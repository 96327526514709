import {
  ADD_COUNTRY_ERROR,
  ADD_COUNTRY_SUCCESS,
  COUNTRY_DETAILS_ERROR,
  COUNTRY_DETAILS_SUCCESS,
  COUNTRY_LIST_ERROR,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_UPDATE_ERROR,
  COUNTRY_UPDATE_SUCCESS,
  DELETE_COUNTRY_ERROR,
  DELETE_COUNTRY_SUCCESS,
  REQ_ADD_COUNTRY,
  REQ_COUNTRY_DETAILS,
  REQ_COUNTRY_LIST,
  REQ_DELETE_COUNTRY,
  REQ_UPDATE_COUNTRY,
} from './actionType'

const IntialState = {
  addCountry: null,
  addCountryError: null,
  countries: null,
  countriesError: null,
  countryToggle: false,
  updatecountry: null,
  updateCountryError: null,
  detailCountry: null,
  detailCountryError: null,
  deleteCountry: null,
  deleteCountryError: null,
}

const Country = (state = IntialState, action) => {
  switch (action.type) {
    case REQ_COUNTRY_LIST:
      return {
        ...state,
        countryToggle: true,
      }
    case COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        countryToggle: false,
      }
    case COUNTRY_LIST_ERROR:
      return {
        ...state,
        countriesError: action.payload,
        countryToggle: false,
      }

    case REQ_UPDATE_COUNTRY:
      return {
        ...state,
        countryToggle: true,
      }
    case COUNTRY_UPDATE_SUCCESS:
      return {
        ...state,
        updatecountry: action.payload,
        countryToggle: false,
      }
    case COUNTRY_UPDATE_ERROR:
      return {
        ...state,
        updateCountryError: action.payload,
        countryToggle: false,
      }

    case REQ_COUNTRY_DETAILS:
      return {
        ...state,
        countryToggle: true,
      }
    case COUNTRY_DETAILS_SUCCESS:
      return {
        ...state,
        detailCountry: action.payload,
        countryToggle: false,
      }
    case COUNTRY_DETAILS_ERROR:
      return {
        ...state,
        detailCountryError: action.payload,
        countryToggle: false,
      }

    case REQ_ADD_COUNTRY:
      return {
        ...state,
        countryToggle: true,
      }
    case ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        addCountry: action.payload,
        countryToggle: false,
      }
    case ADD_COUNTRY_ERROR:
      return {
        ...state,
        addCountryError: action.payload,
        countryToggle: false,
      }

    case REQ_DELETE_COUNTRY:
      return {
        ...state,
        countryToggle: true,
      }
    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        deleteCountry: action.payload,
        countryToggle: false,
      }
    case DELETE_COUNTRY_ERROR:
      return {
        ...state,
        deleteCountryError: action.payload,
        countryToggle: false,
      }

    default:
      return state
  }
}

export default Country
