import axios from './axios'
// import { SERVER_URL } from '../helpers/api_url'

// const axios = axiosInstance

export const intrestListApi = () => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/common/Interest?country=IN`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const addIntrestApi = (data) => {
  console.log(data)
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .post(`/common/Interest`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const deleteIntrestApi = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .delete(`/common//Interest?interestId=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const UpdatIntrestList = (data) => {
  console.log(data, 'data')
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .patch(`/common/Interest`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const getSubIntrestApi = (id) => {
  console.log(id, 'id')
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/common/SubInterest?interestId=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const deleteSubIntrestApi = (data) => {
  console.log(data, 'id')
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `/common/SubInterest?interestId=${data.interestId}&subInterestId=${data.subInterestId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      )
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const UpdatSubIntrestList = (data) => {
  console.log(data, 'data')
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .patch(`/common/SubInterest`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const getDetailsSubIntrestApi = ({ id, subInterestId }) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/common/SubInterest/${id}?subInterestId=${subInterestId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const addSubIntrestApi = (data) => {
  console.log(data)
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .post(`/common/SubInterest`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}
