import axios from './axios'

export const bannerListApi = () => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/common/banner`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const getBannerDetails = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/common/banner/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const addBannerApi = (formData) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .post(`/common/banner`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const updateBanner = (data) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .patch(`/common/banner`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const deleteBannerApi = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .delete(`/common/banner?bannerId=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}
