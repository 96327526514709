export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const GENERATE_TOKEN = 'GENERATE_TOKEN'
export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS'
export const GENERATE_TOKEN_ERROR = 'GENERATE_TOKEN_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const API_ERROR = 'API_ERROR'
