import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
// takeLatest
import { GENERATE_TOKEN, LOGIN_USER } from './actionTypes'

import { loginSuccess, apiError, generateTokenSuccess, generateTokenError } from './action'

import { generateTokenApi, loginUsersApi } from '../../../helpers/callApi'

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(loginUsersApi, user)
    yield put(loginSuccess(response))
    navigate('/homePage')
  } catch (err) {
    yield put(apiError(err))
  }
}

function* generateToken() {
  try {
    const response = yield call(generateTokenApi)
    yield put(generateTokenSuccess(response))
  } catch (err) {
    yield put(generateTokenError(err))
  }
}

export function* watchgenerateToken() {
  yield takeEvery(GENERATE_TOKEN, generateToken)
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

function* authSaga() {
  yield all([fork(watchgenerateToken), fork(watchUserLogin)])
}

export default authSaga
