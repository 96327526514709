import { takeLatest, fork, put, all, call } from 'redux-saga/effects'
import { UpdatUsersList, getUserDetails, usersListApi } from 'src/helpers/callUsers'
import {
  updateUserError,
  updateUserSucces,
  userDetailsError,
  userDetailsSuccess,
  userListError,
  userListSuccess,
} from './action'
import { REQ_UPDATE_USER, REQ_USER_DETAILS, REQ_USER_LIST } from './actionType'

function* fetchUsersList({ payload: req }) {
  try {
    const response = yield call(usersListApi, req)
    yield put(userListSuccess(response))
  } catch (err) {
    yield put(userListError(err))
  }
}

function* fetchUserDetails({ payload: req }) {
  try {
    const response = yield call(getUserDetails, req)
    yield put(userDetailsSuccess(response))
  } catch (err) {
    yield put(userDetailsError(err))
  }
}

function* updateUsersList({ payload: req }) {
  try {
    const response = yield call(UpdatUsersList, req)
    yield put(updateUserSucces(response))
  } catch (err) {
    yield put(updateUserError(err))
  }
}

function* watchUserslist() {
  yield takeLatest(REQ_USER_LIST, fetchUsersList)
}

function* watchUserDetails() {
  yield takeLatest(REQ_USER_DETAILS, fetchUserDetails)
}

function* watchUpdateUserslist() {
  yield takeLatest(REQ_UPDATE_USER, updateUsersList)
}

function* entriesSaga() {
  yield all([fork(watchUserslist), fork(watchUserDetails), fork(watchUpdateUserslist)])
}

export default entriesSaga
