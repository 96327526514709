import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { CCard, CCardBody, CCol, CRow, CButton, CImage } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqUserDetails } from 'src/store/action'
import Loader from 'src/components/Loader'
import { SERVER_URL } from 'src/helpers/api_url'

const UserDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isUserDetailsLoading = useSelector((state) => state.Users?.userToggle)
  const userData = useSelector((state) => state.Users?.userDetails)
  useEffect(() => {
    dispatch(reqUserDetails(id))
  }, [dispatch, id])

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" className="m-2" onClick={() => navigate(-1)}>
            Back
          </CButton>
          {isUserDetailsLoading && <Loader />}

          {!isUserDetailsLoading && (
            <CRow>
              <div className="col-12">
                <CCard>
                  <CCardBody>
                    <CRow className="form-group align-items-center">
                      <div className="clearfix">
                        <CImage
                          align="center"
                          rounded
                          src={userData?.profilePic}
                          width={200}
                          height={200}
                        />
                      </div>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        User Id
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?._id || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        FIrst Name
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.firstName || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Last Name
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.lastName || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Date of Birth
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.dob || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Gender
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.gender || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Roles
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.roles || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Status
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.status || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Created At
                      </label>
                      <CCol sm={10}>
                        <h5>{userData?.CreatedAt || '-'}</h5>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                {/* Relationship Status Section */}
                <CCard className="mt-3">
                  <CCardBody>
                    {/* Relationship Status Section */}
                    <h6>Relationship Status</h6>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Status</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.relationshipStatus?.status || '-'}</p>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Partner Name</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.relationshipStatus?.partnerName || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Languages Section */}
                    <h6 className="mt-4">Languages</h6>
                    {userData?.personalDetails?.languages?.length > 0 ? (
                      userData.personalDetails.languages.map((lang, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <label className="col-sm-2 col-form-label">{lang.language}</label>
                          <CCol sm={10}>
                            <p>{lang.value}</p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Languages Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Zodiac Sign Section */}
                    <h6 className="mt-4">Zodiac Sign</h6>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Zodiac Sign</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.zodiac || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Ethnicity Section */}
                    <h6 className="mt-4">Ethnicity</h6>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Ethnicity</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.ethnicity || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Community Section */}
                    <h6 className="mt-4">Community</h6>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Community</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.community || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Religion Section */}
                    <h6 className="mt-4">Religion</h6>
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Religion</label>
                      <CCol sm={10}>
                        <p>{userData?.personalDetails?.religion || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Looking For Section */}
                    <h6 className="mt-4">Looking For</h6>
                    {userData?.personalDetails?.lookingFor?.length > 0 ? (
                      userData.personalDetails.lookingFor.map((item, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>{item}</p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>Not Looking for Anything</p>
                        </CCol>
                      </CRow>
                    )}
                  </CCardBody>
                </CCard>
                <CCard className="mt-3">
                  <CCardBody>
                    <h6>Professional Details</h6>

                    {/* Job Role */}
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Job Role</label>
                      <CCol sm={10}>
                        <p>{userData?.professionalDetails?.jobRole || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Occupation */}
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Occupation</label>
                      <CCol sm={10}>
                        <p>{userData?.professionalDetails?.occupation || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Highest Education */}
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Highest Education</label>
                      <CCol sm={10}>
                        <p>{userData?.professionalDetails?.highestEducation || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Field of Study */}
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Field of Study</label>
                      <CCol sm={10}>
                        <p>{userData?.professionalDetails?.fieldOfStudy || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Bio */}
                    <CRow className="form-group align-items-center">
                      <label className="col-sm-2 col-form-label">Bio</label>
                      <CCol sm={10}>
                        <p>{userData?.professionalDetails?.bio || '-'}</p>
                      </CCol>
                    </CRow>

                    {/* Skills */}
                    <h6 className="mt-4">Skills</h6>
                    {userData?.professionalDetails?.skills?.length > 0 ? (
                      userData.professionalDetails.skills.map((skill, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>{skill}</p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Skills Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Services */}
                    <h6 className="mt-4">Services</h6>
                    {userData?.professionalDetails?.services?.length > 0 ? (
                      userData.professionalDetails.services.map((service, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>{service}</p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Services Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Experience */}
                    <h6 className="mt-4">Experience</h6>
                    {userData?.professionalDetails?.experience?.length > 0 ? (
                      userData.professionalDetails.experience.map((exp, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>
                              {exp.jobTitle} at {exp.companyName} ({exp.location})
                              <br />
                              {formatDate(exp.startDate)} - {formatDate(exp.endDate)}
                              <br />
                              {exp.description}
                            </p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Experience Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Education */}
                    <h6 className="mt-4">Education</h6>
                    {userData?.professionalDetails?.education?.length > 0 ? (
                      userData.professionalDetails.education.map((edu, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>
                              {edu.degreeName} in {edu.fieldOfStudy} from {edu.instituteName} (
                              {edu.location})
                              <br />
                              {formatDate(edu.startDate)} - {formatDate(edu.endDate)}
                              <br />
                              Grade: {edu.grade}
                            </p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Education Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Certifications */}
                    <h6 className="mt-4">Certifications</h6>
                    {userData?.professionalDetails?.certifications?.length > 0 ? (
                      userData.professionalDetails.certifications.map((cert, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>
                              {cert.courseName} from {cert.issuingInstituteName}
                              <br />
                              {formatDate(cert.startDate)} - {formatDate(cert.endDate)}
                            </p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Certifications Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Awards */}
                    <h6 className="mt-4">Awards</h6>
                    {userData?.professionalDetails?.awards?.length > 0 ? (
                      userData.professionalDetails.awards.map((award, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>
                              {award.title} from {award.issuer} on {award.issueDate}
                              <br />
                              {award.description}
                            </p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Awards Available</p>
                        </CCol>
                      </CRow>
                    )}

                    {/* Volunteer Experience */}
                    <h6 className="mt-4">Volunteer Experience</h6>
                    {userData?.professionalDetails?.volunteerExperience?.length > 0 ? (
                      userData.professionalDetails.volunteerExperience.map((volunteer, index) => (
                        <CRow key={index} className="form-group align-items-center">
                          <CCol sm={12}>
                            <p>
                              {volunteer.role} at {volunteer.organization} (
                              {volunteer.organizationWebsite})
                              <br />
                              {formatDate(volunteer.startDate)} - {formatDate(volunteer.endDate)}
                              <br />
                              {volunteer.organizationDescription}
                            </p>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <CRow className="form-group align-items-center">
                        <CCol sm={12}>
                          <p>No Volunteer Experience Available</p>
                        </CCol>
                      </CRow>
                    )}
                  </CCardBody>
                </CCard>
              </div>
            </CRow>
          )}
          <AppFooter />
        </div>
      </div>
    </div>
  )
}

export default UserDetails
