import {
  ADD_COUNTRY_ERROR,
  ADD_COUNTRY_SUCCESS,
  COUNTRY_DETAILS_ERROR,
  COUNTRY_DETAILS_SUCCESS,
  COUNTRY_LIST_ERROR,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_UPDATE_ERROR,
  COUNTRY_UPDATE_SUCCESS,
  DELETE_COUNTRY_ERROR,
  DELETE_COUNTRY_SUCCESS,
  REQ_ADD_COUNTRY,
  REQ_COUNTRY_DETAILS,
  REQ_COUNTRY_LIST,
  REQ_DELETE_COUNTRY,
  REQ_UPDATE_COUNTRY,
} from './actionType'

export const reqCountryList = (req) => {
  return {
    type: REQ_COUNTRY_LIST,
    payload: req,
  }
}

export const countryListSuccess = (req) => {
  return {
    type: COUNTRY_LIST_SUCCESS,
    payload: req,
  }
}

export const countryListError = (req) => {
  return {
    type: COUNTRY_LIST_ERROR,
    payload: req,
  }
}

export const reqCountryUpdate = (req) => {
  return {
    type: REQ_UPDATE_COUNTRY,
    payload: req,
  }
}

export const updateCountrySuccess = (req) => {
  return {
    type: COUNTRY_UPDATE_SUCCESS,
    payload: req,
  }
}

export const updateCountryError = (req) => {
  return {
    type: COUNTRY_UPDATE_ERROR,
    payload: req,
  }
}

export const reqCountryDetails = (req) => {
  return {
    type: REQ_COUNTRY_DETAILS,
    payload: req,
  }
}

export const CountryDetailSuccess = (res) => {
  return {
    type: COUNTRY_DETAILS_SUCCESS,
    payload: res,
  }
}

export const CountryDetailError = (res) => {
  return {
    type: COUNTRY_DETAILS_ERROR,
    payload: res,
  }
}

export const reqAddCountry = (req, navigate) => {
  return {
    type: REQ_ADD_COUNTRY,
    payload: req,
    navigate,
  }
}

export const addCountrySuccess = (req) => {
  return {
    type: ADD_COUNTRY_SUCCESS,
    payload: req,
  }
}

export const addCountryError = (req) => {
  return {
    type: ADD_COUNTRY_ERROR,
    payload: req,
  }
}

export const reqDeleteCountry = (req) => {
  return {
    type: REQ_DELETE_COUNTRY,
    payload: req,
  }
}
export const deleteCountrySuccess = (req) => {
  return {
    type: DELETE_COUNTRY_SUCCESS,
    payload: req,
  }
}
export const deleteCountryError = (req) => {
  return {
    type: DELETE_COUNTRY_ERROR,
    payload: req,
  }
}
