// src/store/actions.js

import {
  ADD_BANNER_ERROR,
  ADD_BANNER_SUCCESS,
  BANNER_DETAILS_ERROR,
  BANNER_DETAILS_SUCCESS,
  BANNER_LIST_ERROR,
  BANNER_LIST_SUCCESS,
  BANNER_UPDATE_ERROR,
  BANNER_UPDATE_SUCCESS,
  DELETE_BANNER_ERROR,
  DELETE_BANNER_SUCCESS,
  REQ_ADD_BANNER,
  REQ_BANNER_DETAILS,
  REQ_BANNER_LIST,
  REQ_DELETE_BANNER,
  REQ_UPDATE_BANNER,
} from './actionType'

// Action Creators
export const reqAddBanner = (data, navigate) => ({
  type: REQ_ADD_BANNER,
  payload: { data, navigate },
})

export const reqBannerDetails = (id) => ({
  type: REQ_BANNER_DETAILS,
  payload: id,
})

export const reqBannerList = () => ({
  type: REQ_BANNER_LIST,
})

export const reqDeleteBanner = (id) => ({
  type: REQ_DELETE_BANNER,
  payload: id,
})

export const reqUpdateBanner = (id, data, navigate) => ({
  type: REQ_UPDATE_BANNER,
  payload: { id, data, navigate },
})

// Success and Error Actions
export const addBannerSuccess = (data) => ({
  type: ADD_BANNER_SUCCESS,
  payload: data,
})

export const addBannerError = (error) => ({
  type: ADD_BANNER_ERROR,
  payload: error,
})

export const bannerDetailsSuccess = (data) => ({
  type: BANNER_DETAILS_SUCCESS,
  payload: data,
})

export const bannerDetailsError = (error) => ({
  type: BANNER_DETAILS_ERROR,
  payload: error,
})

export const bannerListSuccess = (data) => ({
  type: BANNER_LIST_SUCCESS,
  payload: data,
})

export const bannerListError = (error) => ({
  type: BANNER_LIST_ERROR,
  payload: error,
})

export const updateBannerSuccess = (data) => ({
  type: BANNER_UPDATE_SUCCESS,
  payload: data,
})

export const updateBannerError = (error) => ({
  type: BANNER_UPDATE_ERROR,
  payload: error,
})

export const deleteBannerSuccess = (id) => ({
  type: DELETE_BANNER_SUCCESS,
  payload: id,
})

export const deleteBannerError = (error) => ({
  type: DELETE_BANNER_ERROR,
  payload: error,
})
