import React from 'react'
import UserUpdate from './views/pages/users/userUpdate'
const GetInterestList = React.lazy(() => import('./views/pages/interest/getInterestList'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const GetCountry = React.lazy(() => import('./views/pages/country/getCountry'))
const AddCountryList = React.lazy(() => import('./views/pages/country/addCountry'))
const UpdateCountryList = React.lazy(() => import('./views/pages/country/updateCountry'))
const CountryDetails = React.lazy(() => import('./views/pages/country/countryDetails'))
const AddInterest = React.lazy(() => import('./views/pages/interest/addInterest'))
const UpdataeInterest = React.lazy(() => import('./views/pages/interest/UpdataeInterest'))
const SubInterestList = React.lazy(() => import('./views/pages/subInterest/subInterestList'))
const UpdateSubInterest = React.lazy(() => import('./views/pages/subInterest/updateSubInterest'))
const SubInterestDetails = React.lazy(() => import('./views/pages/subInterest/subInterestDetails'))
const UserList = React.lazy(() => import('./views/pages/users/userList'))
const UserDetails = React.lazy(() => import('./views/pages/users/userDetails'))
const AddSubInterest = React.lazy(() => import('./views/pages/subInterest/addSubInterest'))
const AddBanner = React.lazy(() => import('./views/pages/banner/addBanner'))
const BannerList = React.lazy(() => import('./views/pages/banner/getBannerList'))
const UpdateBanner = React.lazy(() => import('./views/pages/banner/updateBanner'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/login', name: 'Login', element: Login },
  { path: '/getCountry', name: 'country List', element: GetCountry },
  { path: '/addCountryList', name: 'Add Country Details', element: AddCountryList },
  { path: '/updateCountryList/:id', name: 'Update Country Details', element: UpdateCountryList },
  { path: '/countryDetails/:id', name: 'country Details', element: CountryDetails },
  { path: '/getInterestList', name: 'Interest List', element: GetInterestList },
  { path: '/addInterest', name: 'Add interest', element: AddInterest },
  { path: '/updateInterest/:id', name: 'Update Interest', element: UpdataeInterest },
  { path: '/getSubInterest/:id', name: 'sub Interest List', element: SubInterestList },
  {
    path: '/updateSubInterest/:id/:subInterestId',
    name: 'Update SubInterest',
    element: UpdateSubInterest,
  },
  {
    path: '/subInterestDetails/:id/:subInterestId',
    name: 'SubInterest Details',
    element: SubInterestDetails,
  },
  { path: '/userList', name: 'User List', element: BannerList },
  { path: '/UserDetails/:id', name: 'User Details', element: UserDetails },
  { path: '/UserUpdate/:id', name: 'User Update', element: UserUpdate },
  { path: '/addSubInterest', name: 'Add Sub interest', element: AddSubInterest },
  { path: '/addBanner', name: 'Add Banner', element: UpdateBanner },
  { path: '/getBannerList', name: 'Banner List', element: BannerList },
  { path: '/updateBanner/:id', name: 'Update Banner', element: UpdateBanner },
]

export default routes
