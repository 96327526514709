import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import dummyInterest from '../interest/dummyData'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqSubIntrest, reqSubIntrestDelete } from 'src/store/action'
import { SERVER_URL } from 'src/helpers/api_url'

const SubInterestList = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Intrest?.getSubintrest?.data?.subInterest)

  useEffect(() => {
    dispatch(reqSubIntrest(id))
  }, [dispatch, id])
  const deleteSubintrest = (interestId, subInterestId) => {
    if (window.confirm(`Are you sure, you want to delele SubIntrest permenently?`)) {
      dispatch(reqSubIntrestDelete(interestId, subInterestId))
    }
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate(`/addSubInterest/${id}`)}>
                Add SubInterest
              </CButton>
              <CCardBody>
                <CTable striped>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Sub Interest Name</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Image</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {details?.length > 0 && (
                    <CTableBody>
                      {details?.map((subInterest, index) => (
                        <CTableRow key={index}>
                          <CTableDataCell>{subInterest?.name}</CTableDataCell>

                          <CTableDataCell key={index}>
                            <img
                              src={subInterest?.imageUrl}
                              style={{
                                width: '70px',
                                height: '70px',
                                objectFit: 'cover',
                                borderRadius: '5px',
                              }}
                              alt="Interest"
                            ></img>
                          </CTableDataCell>

                          <CTableDataCell>{subInterest?.status}</CTableDataCell>
                          <CTableDataCell>
                            <CButton
                              class="btn btn-outline-primary btn-loading m-2"
                              onClick={() =>
                                navigate(`/updateSubInterest/${id}/${subInterest._id}`)
                              }
                            >
                              Update
                            </CButton>
                            <CButton
                              class="btn btn-outline-primary btn-loading"
                              onClick={() =>
                                navigate(`/subInterestDetails/${id}/${subInterest._id}`)
                              }
                            >
                              Details
                            </CButton>
                            <CButton type="Button" class="btn btn-outline-primary btn-loading m-2">
                              <AiOutlineDelete onClick={() => deleteSubintrest(subInterest._id)} />
                            </CButton>
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  )}
                </CTable>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default SubInterestList
