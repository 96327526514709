import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { CCard, CCardBody, CCol, CRow, CButton } from '@coreui/react'
import Moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import Loader from 'src/components/Loader'
import { reqCountryDetails } from 'src/store/action'
const CountryDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isCountryListLoading = useSelector((state) => state.Country.countryToggle)

  const selectedCountry = useSelector((state) => state.Country?.detailCountry?.data)

  useEffect(() => {
    dispatch(reqCountryDetails(id))
  }, [dispatch, id])

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          {isCountryListLoading && <Loader />}
          {!isCountryListLoading && (
            <CRow>
              <div className="col-12">
                <CCard>
                  <CCardBody>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Country code
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.countryCode || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Country Name
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.countryName || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Police HelpLine Contact
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.policeHelpLineContact || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Fire HelpLine Contact
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.fireHelpLineContact || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Ambulanc HelpLine Contact
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.AmbulancHelpLineContact || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Sos Limit Count
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.sosLimitCount || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Likes Limits
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.likesLimits || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Distance Unit
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.distanceUnit || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Terms And Condition
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.termsAndCondition || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Privacy Policy
                      </label>
                      <CCol sm={10}>
                        <h5>{selectedCountry?.privacyPolicy || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Create Date
                      </label>
                      <CCol sm={10}>
                        <h5>{Moment(selectedCountry?.created_at).format('DD/MM/YYYY') || '-'}</h5>
                      </CCol>
                    </CRow>
                    <CRow className="form-group align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        Update Date
                      </label>
                      <CCol sm={10}>
                        <h5>{Moment(selectedCountry?.updatedAt).format('DD/MM/YYYY') || '-'}</h5>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </div>
            </CRow>
          )}
          <AppFooter />
        </div>
      </div>
    </div>
  )
}

export default CountryDetails
