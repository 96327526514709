import React, { Component, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import UserList from './views/pages/users/userList'
import GetCountry from './views/pages/country/getCountry'
import GetInterestList from './views/pages/interest/getInterestList'
import HomePage from './views/pages/dashboard/Dashboard'
import NoPageFound from './views/pages/NoPageFound'
import AddInterest from './views/pages/interest/addInterest'
import UpdataeInterest from './views/pages/interest/UpdataeInterest'
import SubInterestList from './views/pages/subInterest/subInterestList'
import SubInterestDetails from './views/pages/subInterest/subInterestDetails'
import UpdateSubInterest from './views/pages/subInterest/updateSubInterest'
import UserDetails from './views/pages/users/userDetails'
import UserUpdate from './views/pages/users/userUpdate'
import CountryDetails from './views/pages/country/countryDetails'
import UpdateCountryList from './views/pages/country/updateCountry'
import AddCountryList from './views/pages/country/addCountry'
import AddSubInterest from './views/pages/subInterest/addSubInterest'
import AddBanner from './views/pages/banner/addBanner'
import BannerList from './views/pages/banner/getBannerList'
import UpdateBanner from './views/pages/banner/updateBanner'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))

class App extends Component {
  render() {
    return (
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/homePage" element={<HomePage />} />
          <Route path="/userList" element={<UserList />} />
          <Route path="/userDetails/:id" element={<UserDetails />} />
          <Route path="/UserUpdate/:id" element={<UserUpdate />} />
          <Route path="/getCountry" element={<GetCountry />} />
          <Route path="/getInterestList" element={<GetInterestList />} />
          <Route path="/addInterest" element={<AddInterest />} />
          <Route path="/updateInterest/:id" element={<UpdataeInterest />} />
          <Route path="/getSubInterest/:id" element={<SubInterestList />} />
          <Route path="/subInterestDetails/:id/:subInterestId" element={<SubInterestDetails />} />
          <Route path="/updateSubInterest/:id/:subInterestId" element={<UpdateSubInterest />} />
          <Route path="/getCountry" element={<GetCountry />} />
          <Route path="/countryDetails/:id" element={<CountryDetails />} />
          <Route path="/updateCountryList/:id" element={<UpdateCountryList />} />
          <Route path="/addCountryList/" element={<AddCountryList />} />
          <Route path="/addSubInterest/:id" element={<AddSubInterest />} />
          <Route path="/addBanner" element={<AddBanner />} />
          <Route path="/getBannerList" element={<BannerList />} />
          <Route path="/updateBanner/:id" element={<UpdateBanner />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </Suspense>
    )
  }
}

export default App
