export const REQ_INTREST_LIST = 'REQ_INTREST_LIST'
export const INTREST_LIST_SUCCESS = 'INTREST_LIST_SUCCESS'
export const INTREST_LIST_ERROR = 'INTREST_LIST_ERROR'

export const REQ_ADD_INTREST = 'REQ_ADD_INTREST'
export const ADD_INTREST_SUCCESS = 'ADD_INTREST_SUCCESS'
export const ADD_INTREST_ERROR = 'ADD_INTREST_ERROR'

export const REQ_DELETE_INTREST = 'REQ_DELETE_INTREST'
export const DELETE_INTREST_SUCCESS = 'DELETE_INTREST_SUCCESS'
export const DELETE_INTREST_ERROR = 'DELETE_INTREST_ERROR'

export const REQ_UPDATE_INTREST = 'REQ_UPDATE_INTREST'
export const UPDATE_INTREST_SUCCESS = 'UPDATE_INTREST_SUCCESS'
export const UPDATE_INTREST_ERROR = 'UPDATE_INTREST_ERROR'

export const REQ_FOR_SUBINTREST = 'REQ_FOR_SUBINTREST'
export const UPDATE_SUBINTREST_SUCCESS = 'UPDATE_SUBINTREST_SUCCESS'
export const UPDATE_SUBINTREST_ERROR = 'UPDATE_SUBINTREST_ERROR'

export const REQ_DELETE_SUBINTREST = 'REQ_DELETE_SUBINTREST'
export const DELETE_SUBINTREST_SUCCESS = 'DELETE_SUBINTREST_SUCCESS'
export const DELETE_SUBINTREST_ERROR = 'DELETE_SUBINTREST_ERROR'

export const REQ_FOR_UPDATESUBINTREST = 'REQ_FOR_UPDATESUBINTREST'
export const UPDATE_UPDATESUBINTREST_SUCCESS = 'UPDATE_UPDATESUBINTREST_SUCCESS'
export const UPDATE_UPDATESUBINTREST_ERROR = 'UPDATE_UPDATESUBINTREST_ERROR'

export const REQ_FOR_SUBINTREST_DETAILS = 'REQ_FOR_SUBINTREST_DETAILS'
export const UPDATE_SUBINTREST_SUCCESS_DETAILS = 'UPDATE_SUBINTREST_SUCCESS_DETAILS'
export const UPDATE_SUBINTREST_ERROR_DETAILS = 'UPDATE_SUBINTREST_ERROR_DETAILS'

export const REQ_ADD_SUBINTREST = 'REQ_ADD_SUBINTREST'
export const ADD_SUBINTREST_SUCCESS = 'ADD_SUBINTREST_SUCCESS'
export const ADD_SUBINTREST_ERROR = 'ADD_SUBINTREST_ERROR'
