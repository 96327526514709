import React, { useState, useEffect } from 'react'
import { getCode, getData } from 'country-list'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CInputGroup } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch } from 'react-redux'
import { reqAddCountry } from 'src/store/action'
const AddCountryList = () => {
  const countryList = getData()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, watch } = useForm()
  const [ckData, setCkData] = useState({
    termsAndCondition: '',
    privacyPolicy: '',
    cancellationPolicy: '',
  })
  const onSubmit = async (data) => {
    const countryCode = getCode(data.country)
    const countryName = data.country
    const likesLimits = data.likesLimits
    const AmbulancHelpLineContact = data.AmbulancHelpLineContact
    const cancellationPolicy = data.cancellationPolicy
    const sosLimitCount = data.sosLimitCount
    const policeHelpLineContact = data.policeHelpLineContact
    const fireHelpLineContact = data.fireHelpLineContact
    const distanceUnit = data.distanceUnit
    const termsAndCondition = ckData.termsAndCondition
    const privacyPolicy = ckData.privacyPolicy
    const newData = {
      countryName,
      countryCode,
      AmbulancHelpLineContact,
      cancellationPolicy,
      sosLimitCount,
      policeHelpLineContact,
      fireHelpLineContact,
      distanceUnit,
      likesLimits,
      termsAndCondition,
      privacyPolicy,
    }
    dispatch(reqAddCountry(newData, navigate))
  }
  return (
    <CRow>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Country Name
                        </label>
                        <CCol sm={10}>
                          <select
                            className="form-control m-2"
                            required
                            {...register('country', { required: true })}
                          >
                            <option value="">Choose country</option>
                            {countryList?.map((country) => (
                              <option key={country.code}>{country.name}</option>
                            ))}
                          </select>
                        </CCol>
                      </div>
                    </div>
                  </CInputGroup>
                  {/* <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Country code
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="add country code"
                          {...register('countryCode')}
                        />
                      </div>
                    </div>
                  </CInputGroup> */}
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Sos Limit
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="add sos Limit"
                          {...register('sosLimitCount')}
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Likes Limits
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="add Likes Limit"
                          {...register('likesLimits')}
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Distance Unit
                        </label>
                      </div>
                      <div className="col-auto">
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'Km'}
                            {...register('distanceUnit')}
                          />{' '}
                          Km
                        </label>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'Miles'}
                            {...register('distanceUnit')}
                          />{' '}
                          Miles
                        </label>
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Police helpLine
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="police helpLine"
                          {...register('policeHelpLineContact')}
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          fire helpLine
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="fire helpLine"
                          {...register('fireHelpLineContact')}
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Ambulance helpLine
                        </label>
                      </div>
                      <div className="col-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ambulance helpLine"
                          {...register('AmbulancHelpLineContact')}
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          Privacy Policy
                        </label>
                      </div>
                      <div className="col-auto">
                        <CKEditor
                          editor={ClassicEditor}
                          data=""
                          config={{
                            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                          }}
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            data = data.replace(/<[^>]+>/g, '')
                            setCkData({ ...ckData, privacyPolicy: data })
                          }}
                          placeholder="privacyPolicy"
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <div className="row g-3 align-items-center">
                      <div className="col-auto">
                        <label htmlFor="text" className="col-form-label">
                          terms & Condition
                        </label>
                      </div>
                      <div className="col-auto">
                        <CKEditor
                          editor={ClassicEditor}
                          data=""
                          config={{
                            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                          }}
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            data = data.replace(/<[^>]+>/g, '')
                            setCkData({ ...ckData, termsAndCondition: data })
                          }}
                          placeholder="termsAndCondition"
                        />
                      </div>
                    </div>
                  </CInputGroup>
                  <CRow>
                    <div className="d-grid gap-2">
                      <CButton type="submit" color="light" size="sm">
                        Add
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(-1)}>
                        cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCol>
          <AppFooter />
        </div>
      </div>
    </CRow>
  )
}

export default AddCountryList
