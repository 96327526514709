import axios from './axios'
// import { SERVER_URL } from '../helpers/api_url'

// const axios = SERVER_URL

export const getCountryList = () => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/country`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const getCountryDetails = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/country/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const UpdatCountryList = (data) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/country?countryId=${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const addCountryData = (data) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/country`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}

export const deleteCountryApi = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/country?id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => reject(err))
  })
}
