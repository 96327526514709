import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqCountryList, reqDeleteCountry } from 'src/store/action'
import Loader from 'src/components/Loader'

const GetCountry = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const Country_data = useSelector((state) => state.Country?.countries)
  const isCountryListLoading = useSelector((state) => state.Country?.countryToggle)

  useEffect(() => {
    dispatch(reqCountryList())
  }, [dispatch])

  const deleteCountry = (id) => {
    if (window.confirm(`Are you sure, you want to delele country permenently?`)) {
      dispatch(reqDeleteCountry(id))
    }
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addCountryList')}>
                Add Country
              </CButton>
              {isCountryListLoading && <Loader />}
              {!isCountryListLoading && (
                <CCardBody>
                  <CTable striped>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Country Code</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Country name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Create at</CTableHeaderCell>
                        <CTableHeaderCell scope="col">action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    {Country_data?.length > 0 && (
                      <CTableBody>
                        {Country_data?.map((countryDetails, index) => (
                          <CTableRow key={index}>
                            <CTableDataCell key={index}>
                              {countryDetails?.countryCode}
                            </CTableDataCell>
                            <CTableDataCell>{countryDetails?.countryName}</CTableDataCell>
                            <CTableDataCell>
                              {Moment(countryDetails?.updatedAt).format('DD/MM/YYYY')}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                class="btn btn-outline-primary btn-loading"
                                onClick={() => navigate(`/updateCountryList/${countryDetails._id}`)}
                              >
                                Update
                              </CButton>
                              <CButton
                                class="m-2 btn btn-outline-primary btn-loading"
                                onClick={() => navigate(`/countryDetails/${countryDetails._id}`)}
                              >
                                Details
                              </CButton>
                              <CButton type="Button" class="btn btn-outline-primary btn-loading">
                                <AiOutlineDelete
                                  onClick={() => deleteCountry(countryDetails._id)}
                                />
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    )}
                  </CTable>
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}
export default GetCountry
