import {
  ADD_INTREST_ERROR,
  ADD_INTREST_SUCCESS,
  DELETE_INTREST_ERROR,
  DELETE_INTREST_SUCCESS,
  DELETE_SUBINTREST_ERROR,
  DELETE_SUBINTREST_SUCCESS,
  INTREST_LIST_ERROR,
  INTREST_LIST_SUCCESS,
  REQ_ADD_INTREST,
  REQ_DELETE_INTREST,
  REQ_DELETE_SUBINTREST,
  REQ_FOR_SUBINTREST,
  REQ_FOR_SUBINTREST_DETAILS,
  REQ_FOR_UPDATESUBINTREST,
  REQ_INTREST_LIST,
  REQ_UPDATE_INTREST,
  UPDATE_INTREST_ERROR,
  UPDATE_INTREST_SUCCESS,
  UPDATE_SUBINTREST_ERROR,
  UPDATE_SUBINTREST_ERROR_DETAILS,
  UPDATE_SUBINTREST_SUCCESS,
  UPDATE_SUBINTREST_SUCCESS_DETAILS,
  UPDATE_UPDATESUBINTREST_ERROR,
  UPDATE_UPDATESUBINTREST_SUCCESS,
  REQ_ADD_SUBINTREST,
  ADD_SUBINTREST_ERROR,
  ADD_SUBINTREST_SUCCESS,
} from './actionType'

export const reqIntrestList = (req) => {
  return {
    type: REQ_INTREST_LIST,
    payload: req,
  }
}

export const intrestListSuccess = (req) => {
  return {
    type: INTREST_LIST_SUCCESS,
    payload: req,
  }
}

export const intrestListError = (req) => {
  return {
    type: INTREST_LIST_ERROR,
    payload: req,
  }
}

export const reqAddIntrest = (req, navigate) => {
  return {
    type: REQ_ADD_INTREST,
    payload: req,
    navigate,
  }
}

export const intrestAddSuccess = (req) => {
  return {
    type: ADD_INTREST_SUCCESS,
    payload: req,
  }
}

export const intrestAddError = (req) => {
  return {
    type: ADD_INTREST_ERROR,
    payload: req,
  }
}

export const reqIntrestDelete = (req) => {
  return {
    type: REQ_DELETE_INTREST,
    payload: req,
  }
}

export const intrestDeleteSuccess = (req) => {
  return {
    type: DELETE_INTREST_SUCCESS,
    payload: req,
  }
}

export const intrestDeleteError = (req) => {
  return {
    type: DELETE_INTREST_ERROR,
    payload: req,
  }
}

export const reqIntrestUpdate = (req) => {
  return {
    type: REQ_UPDATE_INTREST,
    payload: req,
  }
}

export const intrestUpdateSuccess = (req) => {
  return {
    type: UPDATE_INTREST_SUCCESS,
    payload: req,
  }
}

export const intrestUpdateError = (req) => {
  return {
    type: UPDATE_INTREST_ERROR,
    payload: req,
  }
}

export const reqSubIntrest = (req) => {
  return {
    type: REQ_FOR_SUBINTREST,
    payload: req,
  }
}

export const SubintrestSuccess = (req) => {
  return {
    type: UPDATE_SUBINTREST_SUCCESS,
    payload: req,
  }
}

export const SubintrestError = (req) => {
  return {
    type: UPDATE_SUBINTREST_ERROR,
    payload: req,
  }
}

export const reqSubIntrestDelete = (req) => {
  return {
    type: REQ_DELETE_SUBINTREST,
    payload: req,
  }
}

export const DeleteSubintrestSuccess = (req) => {
  return {
    type: DELETE_SUBINTREST_SUCCESS,
    payload: req,
  }
}

export const DeleteSubintrestError = (req) => {
  return {
    type: DELETE_SUBINTREST_ERROR,
    payload: req,
  }
}

export const reqSubIntrestUpdate = (req) => {
  return {
    type: REQ_FOR_UPDATESUBINTREST,
    payload: req,
  }
}

export const subIntrestUpdateSuccess = (req) => {
  return {
    type: UPDATE_UPDATESUBINTREST_SUCCESS,
    payload: req,
  }
}

export const subIntrestUpdateError = (req) => {
  return {
    type: UPDATE_UPDATESUBINTREST_ERROR,
    payload: req,
  }
}

export const reqSubIntrestDetails = (req) => {
  return {
    type: REQ_FOR_SUBINTREST_DETAILS,
    payload: req,
  }
}

export const SubintrestSuccessDetails = (res) => {
  return {
    type: UPDATE_SUBINTREST_SUCCESS_DETAILS,
    payload: res,
  }
}

export const SubintrestErrorDetails = (req) => {
  return {
    type: UPDATE_SUBINTREST_ERROR_DETAILS,
    payload: req,
  }
}

export const reqAddSubIntrest = (req, navigate) => {
  return {
    type: REQ_ADD_SUBINTREST,
    payload: req,
    navigate,
  }
}

export const subintrestAddSuccess = (req) => {
  return {
    type: ADD_SUBINTREST_SUCCESS,
    payload: req,
  }
}

export const subintrestAddError = (req) => {
  return {
    type: ADD_SUBINTREST_ERROR,
    payload: req,
  }
}
