import axios from './axios' // Make sure there are no extra spaces here

// import { SERVER_URL } from '../helpers/api_url'

// const axios = SERVER_URL

export const loginUsersApi = (data) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/loginUser`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data)
        sessionStorage.setItem('loginInfo', JSON.stringify(res.data.token))
      })
      .catch((err) => reject(err))
  })
}

export const generateTokenApi = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/generateToken`, {
        deviceId: '63e32384fcaf59466131abc3',
        role: 'ADMIN',
      })
      .then((res) => {
        resolve(res.data)
        sessionStorage.setItem('loginInfo', JSON.stringify(res.data.token))
      })
      .catch((err) => reject(err))
  })
}
