// src/store/actionType.js

export const ADD_BANNER_ERROR = 'ADD_BANNER_ERROR'
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS'

export const BANNER_DETAILS_ERROR = 'BANNER_DETAILS_ERROR'
export const BANNER_DETAILS_SUCCESS = 'BANNER_DETAILS_SUCCESS'

export const BANNER_LIST_ERROR = 'BANNER_LIST_ERROR'
export const BANNER_LIST_SUCCESS = 'BANNER_LIST_SUCCESS'

export const BANNER_UPDATE_ERROR = 'BANNER_UPDATE_ERROR'
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS'

export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'

export const REQ_ADD_BANNER = 'REQ_ADD_BANNER'
export const REQ_BANNER_DETAILS = 'REQ_BANNER_DETAILS'
export const REQ_BANNER_LIST = 'REQ_BANNER_LIST'
export const REQ_DELETE_BANNER = 'REQ_DELETE_BANNER'
export const REQ_UPDATE_BANNER = 'REQ_UPDATE_BANNER'
