import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_ERROR,
} from './actionTypes'

export const generateToken = () => {
  return {
    type: GENERATE_TOKEN,
  }
}
export const generateTokenSuccess = (data) => {
  return {
    type: GENERATE_TOKEN_SUCCESS,
    payload: data,
  }
}
export const generateTokenError = (error) => {
  return {
    type: GENERATE_TOKEN_ERROR,
    payload: error,
  }
}
export const loginUser = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  }
}
export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
