export const REQ_USER_LIST = 'REQ_USER_LIST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_ERROR = 'USER_LIST_ERROR'

export const REQ_USER_DETAILS = 'REQ_USER_DETAILS'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR'

export const REQ_UPDATE_USER = 'REQ_UPDATE_USER'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR'
