import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CCardBody, CCol, CRow, CButton } from '@coreui/react'
import dummyInterest from '../interest/dummyData'
import { reqSubIntrestUpdate, reqSubIntrestDetails } from 'src/store/action'
import { useDispatch, useSelector } from 'react-redux'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'

const UpdateSubInterest = () => {
  const { id, subInterestId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const dispatch = useDispatch()
  const { register, handleSubmit, reset } = useForm()
  const [OldData, setOldData] = useState(null)
  const details = useSelector((state) => state.Intrest?.getSubintrestDetails?.subServices[0])
  useEffect(() => {
    dispatch(reqSubIntrestDetails({ id, subInterestId }))
  }, [dispatch, id, subInterestId])

  console.log(details, 'details')
  useEffect(() => {
    details &&
      details.subInterest &&
      details.subInterest.length > 0 &&
      setTimeout(() => setOldData({ ...details.subInterest[0] }), 1000)
  }, [details])

  useEffect(() => {
    reset(OldData)
  }, [reset, OldData])
  const onSubmit = async (data) => {
    // console.log('^^^^^^^')
    console.log(data, 'data')
    const finalData = {
      interestId: id,
      subInterestId,
      name: data.name,
      description: data.description,
      description_2: data.description_2,
      description_3: data.description_3,
      imageUrl: data.imageUrl && data.imageUrl.length > 0 ? data.imageUrl[0] : data.imageUrl,
    }
    // Create a FormData instance
    const formData = new FormData()

    // Append each property to FormData
    formData.append('interestId', finalData.interestId)
    formData.append('subInterestId', finalData.subInterestId)
    formData.append('name', finalData.name)
    formData.append('description', finalData.description)
    formData.append('description_2', finalData.description_2)
    formData.append('description_3', finalData.description_3)

    // Handle imageUrl conditionally
    if (finalData.imageUrl && data.imageUrl.length > 0) {
      formData.append('imageUrl', finalData.imageUrl)
    } else {
      formData.append('imageUrl', finalData.imageUrl || '')
    }
    console.log('finalData:=========', finalData)
    dispatch(reqSubIntrestUpdate(finalData))
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <CRow>
            <CCol xs={12}>
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Sub Interest Name</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('name')}
                        type="String"
                        placeholder="Interest Name"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Description</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('description')}
                        type="String"
                        placeholder="Description"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 2</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('description_2')}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 3</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('description_3')}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Interest image</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageUrl')}
                        type="file"
                        accept="image/png image/jpeg image/jpg"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={1}
                          {...register('status', { required: true })}
                        />
                        Active
                      </label>
                      <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          className="m-2"
                          type="radio"
                          value={0}
                          {...register('status', { required: true })}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>
                  <CRow className="p-2">
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Update
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(0)}>
                        cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default UpdateSubInterest
