import {
  ADD_INTREST_ERROR,
  ADD_INTREST_SUCCESS,
  DELETE_INTREST_ERROR,
  DELETE_INTREST_SUCCESS,
  DELETE_SUBINTREST_ERROR,
  DELETE_SUBINTREST_SUCCESS,
  INTREST_LIST_ERROR,
  INTREST_LIST_SUCCESS,
  REQ_ADD_INTREST,
  REQ_DELETE_INTREST,
  REQ_DELETE_SUBINTREST,
  REQ_FOR_SUBINTREST,
  REQ_FOR_SUBINTREST_DETAILS,
  REQ_FOR_UPDATESUBINTREST,
  REQ_INTREST_LIST,
  REQ_UPDATE_INTREST,
  UPDATE_INTREST_ERROR,
  UPDATE_INTREST_SUCCESS,
  UPDATE_SUBINTREST_ERROR,
  UPDATE_SUBINTREST_ERROR_DETAILS,
  UPDATE_SUBINTREST_SUCCESS,
  UPDATE_SUBINTREST_SUCCESS_DETAILS,
  UPDATE_UPDATESUBINTREST_ERROR,
  UPDATE_UPDATESUBINTREST_SUCCESS,
  REQ_ADD_SUBINTREST,
  ADD_SUBINTREST_ERROR,
  ADD_SUBINTREST_SUCCESS,
} from './actionType'

const INIT_STATE = {
  intrestList: null,
  intrestError: null,
  intrestAdd: null,
  intrestAddError: null,
  intrestDelte: null,
  intrestDeleteError: null,
  SubintrestDelte: null,
  SubintrestDeleteError: null,
  getSubintrest: null,
  getSubintrestError: null,
  getSubintrestDetails: null,
  getSubintrestErrorDetails: null,
  intrestUpdate: null,
  intrestUpdateError: null,
  subintrestUpdate: null,
  subintrestUpdateError: null,
  intrestToggle: false,
  subintrestAdd: null,
  subintrestAddError: null,
}

const Intrest = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQ_INTREST_LIST:
      return {
        ...state,
        intrestToggle: true,
      }
    case INTREST_LIST_SUCCESS:
      return {
        ...state,
        intrestList: action.payload,
        intrestToggle: false,
      }
    case INTREST_LIST_ERROR:
      return {
        ...state,
        intrestError: action.payload,
        intrestToggle: false,
      }

    case REQ_ADD_INTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case ADD_INTREST_SUCCESS:
      return {
        ...state,
        intrestAdd: action.payload,
        intrestToggle: false,
      }
    case ADD_INTREST_ERROR:
      return {
        ...state,
        intrestAddError: action.payload,
        intrestToggle: false,
      }
    case REQ_DELETE_INTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case DELETE_INTREST_SUCCESS:
      return {
        ...state,
        intrestDelte: action.payload,
        intrestToggle: false,
      }
    case DELETE_INTREST_ERROR:
      return {
        ...state,
        intrestDeleteError: action.payload,
        intrestToggle: false,
      }
    case REQ_UPDATE_INTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case UPDATE_INTREST_SUCCESS:
      return {
        ...state,
        intrestUpdate: action.payload,
        intrestToggle: false,
      }
    case UPDATE_INTREST_ERROR:
      return {
        ...state,
        intrestUpdateError: action.payload,
        intrestToggle: false,
      }
    case REQ_FOR_SUBINTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case UPDATE_SUBINTREST_SUCCESS:
      return {
        ...state,
        getSubintrest: action.payload,
        intrestToggle: false,
      }
    case UPDATE_SUBINTREST_ERROR:
      return {
        ...state,
        getSubintrestError: action.payload,
        intrestToggle: false,
      }
    case REQ_DELETE_SUBINTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case DELETE_SUBINTREST_SUCCESS:
      return {
        ...state,
        SubintrestDelte: action.payload,
        intrestToggle: false,
      }
    case DELETE_SUBINTREST_ERROR:
      return {
        ...state,
        SubintrestDeleteError: action.payload,
        intrestToggle: false,
      }
    case REQ_FOR_UPDATESUBINTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case UPDATE_UPDATESUBINTREST_SUCCESS:
      return {
        ...state,
        subintrestUpdate: action.payload,
        intrestToggle: false,
      }
    case UPDATE_UPDATESUBINTREST_ERROR:
      return {
        ...state,
        subintrestUpdateError: action.payload,
        intrestToggle: false,
      }
    case REQ_FOR_SUBINTREST_DETAILS:
      return {
        ...state,
        intrestToggle: true,
      }
    case UPDATE_SUBINTREST_SUCCESS_DETAILS:
      return {
        ...state,
        getSubintrestDetails: action.payload,
        intrestToggle: false,
      }
    case UPDATE_SUBINTREST_ERROR_DETAILS:
      return {
        ...state,
        getSubintrestErrorDetails: action.payload,
        intrestToggle: false,
      }
    case REQ_ADD_SUBINTREST:
      return {
        ...state,
        intrestToggle: true,
      }
    case ADD_SUBINTREST_SUCCESS:
      return {
        ...state,
        subintrestAdd: action.payload,
        intrestToggle: false,
      }
    case ADD_SUBINTREST_ERROR:
      return {
        ...state,
        subintrestAddError: action.payload,
        intrestToggle: false,
      }
    default:
      return state
  }
}

export default Intrest
