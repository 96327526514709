import { CSpinner } from '@coreui/react'
import React from 'react'

/**
 * Renders the preloader
 */
const Loader = () => {
  return (
    <div className="preloader">
      <div className="status">
        <CSpinner color={'primary'} />
      </div>
    </div>
  )
}

export default Loader
