import { combineReducers } from 'redux'
import Login from './auth/login/reducer'
import Users from './users/reducer'
import Intrest from './intrest/reducer'
import Country from './country/reducer'
import Banner from './banner/reducer'

const rootReducer = combineReducers({
  Login,
  Users,
  Intrest,
  Country,
  Banner,
})

export default rootReducer
