export const REQ_COUNTRY_LIST = 'REQ_COUNTRY_LIST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_ERROR = 'COUNTRY_LIST_ERROR'

export const REQ_UPDATE_COUNTRY = 'REQ_UPDATE_COUNTRY'
export const COUNTRY_UPDATE_SUCCESS = 'COUNTRY_UPDATE_SUCCESS'
export const COUNTRY_UPDATE_ERROR = 'COUNTRY_UPDATE_ERROR'

export const REQ_COUNTRY_DETAILS = 'REQ_COUNTRY_DETAILS'
export const COUNTRY_DETAILS_SUCCESS = 'COUNTRY_DETAILS_SUCCESS'
export const COUNTRY_DETAILS_ERROR = 'COUNTRY_DETAILS_ERROR'

export const REQ_ADD_COUNTRY = 'REQ_ADD_COUNTRY'
export const ADD_COUNTRY_SUCCESS = 'ADD_COUNTRY_SUCCESS'
export const ADD_COUNTRY_ERROR = 'ADD_COUNTRY_ERROR'

export const REQ_DELETE_COUNTRY = 'REQ_DELETE_COUNTRY'
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS'
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR'
