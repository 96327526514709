import { all } from 'redux-saga/effects'
import AuthSaga from './auth/login/saga'
import entriesSaga from './users/saga'
import intrestSaga from './intrest/saga'
import countriesSaga from './country/saga'
import bannerSaga from './banner/saga'

export default function* rootSaga() {
  yield all([AuthSaga(), entriesSaga(), intrestSaga(), countriesSaga(), bannerSaga()])
}
