// src/store/reducers.js

import {
  ADD_BANNER_SUCCESS,
  ADD_BANNER_ERROR,
  BANNER_DETAILS_SUCCESS,
  BANNER_DETAILS_ERROR,
  BANNER_LIST_SUCCESS,
  BANNER_LIST_ERROR,
  BANNER_UPDATE_SUCCESS,
  BANNER_UPDATE_ERROR,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
} from './actionType'

const initialState = {
  banners: [],
  bannerDetails: null,
  loading: false,
  error: null,
}

const bannerReducer = (state = initialState, action) => {
  console.log('state:==========', state)
  switch (action.type) {
    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state.banners, action.payload],
        error: null,
      }
    case ADD_BANNER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        bannerDetails: action.payload,
        error: null,
      }
    case BANNER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case BANNER_LIST_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        error: null,
      }
    case BANNER_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case BANNER_UPDATE_SUCCESS:
      return {
        ...state,
        banners: state.banners.map((banner) =>
          banner.id === action.payload.id ? action.payload : banner,
        ),
        error: null,
      }
    case BANNER_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.filter((banner) => banner.id !== action.payload),
        error: null,
      }
    case DELETE_BANNER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default bannerReducer
