import React from 'react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'

const HomePage = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <h1>Dashboard</h1>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default HomePage
